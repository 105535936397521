import React, { useContext } from 'react'

import MakerEnhance from 'maker-enhance'

import HeadContent from '../../containers/head/head'
import JsonLD from '../../containers/json-ld/jsonLD'

import styles from './pages.module.scss'

import GetStaticPaths from '../../lib/getStaticPaths'
import GetStaticProps from '../../lib/getStaticProps'

import { TrackingContext } from '@speedwaymotors/clutch/Contexts/TrackingContext/TrackingContext'

export async function getStaticPaths() {
  return GetStaticPaths()
}

export async function getStaticProps({ params }) {
  return await GetStaticProps(params)
}

export default function Page(props) {




  const contextObject = {
    ...props
  }

  return (
    <div className={styles.pages_layout}>
      <HeadContent initialPagesContext={contextObject} isSandbox={false} />
      <JsonLD initialPagesContext={contextObject} />

      <MakerEnhance user={'speedway'} />

    </div>
  )
}


