import React from 'react'
import { ProductJsonLd, BreadcrumbJsonLd } from 'next-seo'


const JsonLD = ({ initialPagesContext }) => {

    return false
}

export default JsonLD
